import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import './styles.scss';

import { useGetUser } from '../../hooks/useGetUser';
import { useGetNavegationBlocked } from '../../hooks/useGetNavegationBlocked';
import services from '../../services';
import { getCatalogoLink, getMenu } from '../../services/api';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import ArrowForward from '@material-ui/icons/ArrowForward';
import logoCompany from '../../assets/img/bv.png';
import imageFooterLogo from '../../assets/img/footer.png';
import './styles.scss';
import MenuLink from './components/MenuLink';
import MenuAlt from './components/MenuAlt';
import { showMsgE as showMsgEAction } from '../../store/actions/snackbarActions.js';
import { useDispatch } from 'react-redux';
import globals from '../../globals';
// import AlertaAdminPendente from '../AlertaAdminPendente';
const Menu = () => {
  let ref = useRef();
  const { vagaId } = useParams();
  const history = useHistory();
  const user = useGetUser();
  const isNavegationBlocked = useGetNavegationBlocked();
  const [showMobile, setShowMobile] = React.useState(false);
  const [listaMenu, setListaMenu] = React.useState(null);
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const dispatch = useDispatch();

  const [espelho, SetEspelho] = useState(localStorage.getItem('isEspelho'));

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  useEffect(() => {
    getMenu(user?.userId).then((menu) => {
      menu.menus.push({nome: 'Fale conosco', path: '/fale-conosco', segundoNivel: null})
      console.log("0000",menu.menus)
      setListaMenu(menu.menus);
    });
  }, [user]);
  const [dropdown, setDropdown] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 992) {
        setShowMobile(false);
      }
    }
    window.addEventListener('resize', updateSize);
  }, []);

  const openMenuMobile = () => {
    setShowMobile(!showMobile);
  };

  const closeMenuMobile = () => {
    setShowMobile(false);
  };

  const loginByVaga = () => {
    services.storage.set('vagaId', vagaId);
    history.push(`/`);
  };

  const sair = () => {
    services.storage.clear();
    // history.push(`/`);

    window.location.href = globals.REACT_APP_AUTH_APP;
  };

  const isAuthenticated = true; //service.storage.get('isAuthenticated');
  const isUserCompany = true; //service.storage.get('isUserCompany')
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  const showSubMenu = (id) => {
    var element = document.querySelector('[for=' + id + ']');
    var elements = document.getElementsByClassName('showSub');

    if (elements && elements.length > 0) {
      [...elements].forEach((e) => {
        if (id !== e.getAttribute('for')) e.classList.remove('showSub');
      });
    }

    element.classList.toggle('showSub');
  };
  const clickCatalogolink = async () => {
    var janelaCatalogo = window.open('', '_blank');
    janelaCatalogo.document.write('Carregando...');
    try {
      var url = await getCatalogoLink();
      janelaCatalogo.location.href = url;
    } catch {
      janelaCatalogo.close();
      showMsgE('Não foi possível acessar o catálogo de prêmios.');
    }
  };

  return (
    <>
      <div className="navbar-toggler" onClick={openMenuMobile}>
        <MenuIcon />
      </div>

      <Link to="/">
        <img src={logoCompany} alt="recrutae" className="logoCompany" />
      </Link>

      <div className="menuMain">
        {
          <>
            <ul className={showMobile ? 'show' : 'ul'}>
              {
                /*user.cadastroCompleto*/ true && (
                  <>
                    <li className="noHover">
                      <div className="menuButtonBack" onClick={openMenuMobile}>
                        <ArrowBackRounded htmlColor="#fff" />
                      </div>
                    </li>
                    {listaMenu?.map((menu, index) => {
                      if (
                        menu.nome === 'Resgate' ||
                        menu.nome === 'Minhas Vagas'
                      ) {
                        return null;
                      }
                      return (
                        <>
                          {
                            <MenuLink
                              key={index}
                              label={menu.nome}
                              link={menu.path}
                              id={'subMenu' + index + 1}
                              closeMenuMobile={async () => {
                                if (menu.path == '#catalogo')
                                  await clickCatalogolink();
                                else showSubMenu('subMenu' + index + 1);
                              }}
                              subList={
                                <ul
                                  className="subMenu"
                                  htmlFor={'subMenu' + index + 1}
                                >
                                  {menu?.segundoNivel?.map((subitem, index) => {
                                    return (
                                      <MenuLink
                                        key={index}
                                        label={subitem.nome}
                                        link={subitem.path}
                                        closeMenuMobile={() => {
                                          showSubMenu('subMenu' + index + 1);
                                        }}
                                      />
                                    );
                                  })}
                                </ul>
                              }
                            />
                          }
                        </>
                      );
                    })}

                    <li className="noHover lastItem">
                      <div className="menuFooterWrapper">
                        <img
                          className="menuFooterLogoImage"
                          src={imageFooterLogo}
                          alt="logo"
                        />
                        <span>Dúvidas? Entre em contato através do e-mail: <a href='bvrelacionar@ltmfidelidade.com.br' target='_blank'>bvrelacionar@ltmfidelidade.com.br</a></span>
                      </div>
                    </li>
                  </>
                )
              }
            </ul>
            <div className="wrapeMenuStatus">
              {/* <AlertaAdminPendente /> */}
              <MenuAlt showMobile={showMobile} nome={user.nome} sair={sair} />
            </div>
          </>
        }

        {isAuthenticated && !isUserCompany && (
          <>
            <ul className={showMobile ? 'show' : ''}>
              {user.cadastroCompleto && (
                <li
                  className={
                    splitLocation[1] === 'minhas-vagas' ? 'active' : ''
                  }
                  onClick={closeMenuMobile}
                >
                  <Link to="/minhas-vagas"> Minhas Vagas </Link>
                </li>
              )}

              <li
                className={splitLocation[1] === 'meu-cadastro' ? 'active' : ''}
                onClick={closeMenuMobile}
              >
                <Link to="/meu-cadastro"> Meu Cadastro </Link>
              </li>

              <li className="clickble showOnlyMobile" onClick={sair}>
                <span>Sair </span>
              </li>
            </ul>

            <MenuAlt showMobile={showMobile} nome={user.nome} sair={sair} />
          </>
        )}

        {/* {!isAuthenticated && !isUserCompany && (
          <ul className={showMobile ? 'show' : ''}>
            <li onClick={closeMenuMobile}>
              <span>Já possui cadastro? </span>
            </li>
            <li onClick={closeMenuMobile}>
              <span className="box" onClick={loginByVaga}>
                {' '}
                Entrar{' '}
              </span>
            </li>
          </ul>
        )} */}
      </div>
    </>
  );
};

export default Menu;
