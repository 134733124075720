import React, { memo } from 'react';
import './styles.scss';

const UploadButton = ({
  action,
  returnFormat,
  returnName,
  children,
  acceptTypes,
  showFileName = true,
  limitSize = 3,
  id = 'myFileInput',
}) => {
  // action = retonra arquivo
  // returnFormat = retorna extensão do arquivo
  // returnName = retorna nome arquivo
  // children = label botão
  // acceptTypes = extensões aceitas string separada por virgula '.csv, .txt'
  // showFileName = exibe ou não nome do arquivo ao lado do botão
  // limitSize = tamanho maximo permitido do arquivo em Mega

  const [fileName, setFileName] = React.useState('');
  const [formatosPermitido] = React.useState(
    acceptTypes ? acceptTypes.toUpperCase() : '.TXT, .CSV'
  );
  const [exibirNome] = React.useState(showFileName);
  const [msgError, setMsgError] = React.useState('');
  const [tamanhoMaximo] = React.useState(limitSize * 1000000);

  const getFile = (event) => {
    console.log('file', event.target.files);
    if (!event.target.files.length) {
      if (action) action(null);
      return false;
    }

    let file = event.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileFormat = fileName.substr(fileName.length - 4);

    console.log(file, fileSize, tamanhoMaximo);

    if (!formatosPermitido.includes(fileFormat.toUpperCase())) {
      if (action) action(null);
      setMsgError(
        `Extensão de arquivo inválida, formatos aceitos: ${formatosPermitido}`
      );
      return false;
    }

    if (tamanhoMaximo < fileSize) {
      if (action) action(null);
      setMsgError(`Arquivo muito grande, limite permitido de ${limitSize}Mb`);
      return false;
    }

    setMsgError('');
    setFileName(fileName);
    if (action) action(file);
    if (returnFormat) returnFormat(fileFormat);
    if (returnName) returnName(fileName);
  };

  return (
    <div className="uploadButton">
      <label
        htmlFor={id}
        id={`${id}_label`}
        data-testid={`${id}_label`}
        className="file btn btn-info btn-lg btn-block"
      >
        {children}
      </label>
      <input
        id={id}
        data-testid={id}
        className="modal-uploadInput"
        type="file"
        accept={formatosPermitido}
        onChange={getFile}
      ></input>
      {exibirNome && <span className={`d-block`}>{fileName}</span>}
      {msgError && (
        <span className={`d-block ${msgError ? 'dangerColor' : ''}`}>
          {msgError}
        </span>
      )}
    </div>
  );
};

export default memo(UploadButton);
