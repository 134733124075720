import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';
import { uploadImagem } from '../../services/api';

const ButtonsAnexo = ({
  changeImage,
  texto,
  formatos = ['image/jpeg', 'image/jpg', 'image/png'],
  tamanho,
  nomeArquivo,
  modalLoading,
}) => {
  const ref = useRef();
  const [error, setError] = useState('');
  const [nomeAnexo, setNomeAnexo] = useState('');

  const onImageChange = async (event) => {
    let file;
    setError('');
    if (event.target.files && event.target.files[0]) {
      file = event.target.files[0];
      console.log(file);

      modalLoading(true);
      var link = await uploadImagem(file);
      changeImage(link.data, file.name);
      setNomeAnexo(file.name);
      modalLoading(false);
    }
  };

  const callImageInput = () => {
    ref.current.click();
  };

  return (
    <div className={styles.imageWrapper}>
      <input
        type="file"
        accept="image/*"
        onChange={onImageChange}
        style={{ display: 'none' }}
        ref={ref}
      />
      <button
        onClick={callImageInput}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.imageWrapperIcon}>
          <FontAwesomeIcon icon={faUpload} />
        </div>

        <div className={styles.imageWrapperTexto}>
          {!!nomeAnexo
            ? `${nomeAnexo.substring(0, 20)}${
                nomeAnexo.length > 20 ? '...' : ''
              }`
            : 'Escolher arquivo'}
        </div>
      </button>
      {error && <div className={styles.messageError}>{error}</div>}
      <div className={styles.imageWrapperDetalhes}>
        Formato: JPEG, JPG ou PNG
      </div>
      {tamanho && (
        <div className={styles.imageWrapperDetalhes}>
          Tamanho máx.: {tamanho}
        </div>
      )}
    </div>
  );
};

export default ButtonsAnexo;
