import React, { useEffect, useState } from 'react';
import { useGetUser } from '../../hooks/useGetUser';
import styles from './styles.module.scss';

import { FormControl, MenuItem, TextField } from '@material-ui/core';
import UploadButton from '../../components/Btn/UploadButton';
import ButtonsAnexo from '../../components/ButtonsAnexo';
import { getAssuntoFaleConosco, postFaleConosco } from '../../services/api';
import { trackPromise } from 'react-promise-tracker';
import services from '../../services';
import { showMsgE } from '../../store/actions/snackbarActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch } from 'react-redux';
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';

export default function FaleConosco() {
  const user = useGetUser();
  const [listaAssunto, setListaAssunto] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));
  const [form, setForm] = useState({
    email: '',
    assunto: '',
    anexo: '',
    numeroPedido: '',
    mensagem: '',
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAnexoChange = (anexo) => {
    setForm((prevState) => ({
      ...prevState,
      anexo,
    }));
  };

  function handleFormSubmit() {
    var obj = {
      faleConoscoAssuntoId: form.assunto,
      mensagem: form.mensagem,
      numeroPedido: form.numeroPedido,
      urlArquivo: form.anexo,
    };

    trackPromise(
      services.api
        .postFaleConosco(obj)
        .then((res) => {
          showMsgS(res.data);
          history.push('/home');
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  }
  const fnGetAssuntoFaleConosco = () => {
    trackPromise(
      services.api
        .getAssuntoFaleConosco()
        .then((res) => {
          setListaAssunto(res.data);
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  useEffect(() => {
    fnGetAssuntoFaleConosco();
  }, []);
  return (
    <div className={`${styles.wrapper}`}>
      <div className={styles.title}>Fale Conosco</div>
      <div className={styles.filterWrapper}>
        <div
          className="row"
          style={{ backgroundColor: '#fff', margin: '0px', padding: '0px' }}
        >
          <div className="col-md-7" style={{ padding: '20px' }}>
            <div className={styles.card}>
              <div className={styles.subTitle}>
                Dúvidas, críticas ou sugestões? Envie sua mensagem.
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <FormControl fullWidth>
                    <TextField
                      label="E-mail"
                      type="text"
                      size="small"
                      className="customFilledInput"
                      name="email"
                      disabled
                      value={
                        JSON.parse(localStorage.getItem('authSistema'))?.profile
                          ?.email
                      }
                      onChange={() => {}}
                    />
                  </FormControl>
                </div>
                <div className="col-xs-12 col-md-6">
                  <FormControl fullWidth>
                    <TextField
                      style={{ borderBottom: 'solid 1px #000' }}
                      select
                      label="Assunto"
                      size="small"
                      className="customFilledInput"
                      name="assunto"
                      value={form.assunto}
                      onChange={handleFormChange}
                    >
                      {listaAssunto.map((item) => (
                        <MenuItem
                          key={item.faleConoscoAssuntoId}
                          value={item.faleConoscoAssuntoId}
                        >
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <ButtonsAnexo
                    changeImage={handleAnexoChange}
                    texto="Adicionar Anexo"
                    formatos={['image/jpeg', 'image/jpg', 'image/png']}
                    tamanho={1000000}
                    nomeArquivo={''}
                    modalLoading={() => {}}
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <FormControl fullWidth style={{ marginBottom: '28px' }}>
                    <TextField
                      label="Número do pedido"
                      type="text"
                      size="small"
                      className="customFilledInput"
                      name="numeroPedido"
                      value={form.numeroPedido}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row mx-1">
                <div className="col-xs-12" style={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <TextField
                      label="Mensagem"
                      multiline
                      rows={4}
                      variant="outlined"
                      className="customFilledInput"
                      style={{ borderColor: 'black' }}
                      name="mensagem"
                      value={form.mensagem}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                className="row"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className="col-xs-12 col-md-4">
                  <button
                    disabled={!form.assunto || !form.mensagem}
                    onClick={handleFormSubmit}
                    style={{
                      backgroundColor: '#5CCBE1',
                      color: '#fff',
                      padding: '15px',
                      borderRadius: '5px',
                      border: 'none',
                      cursor: 'pointer',
                      width: '100%',
                    }}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-5"
            style={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              width: '100%',
            }}
          >
            <div className={styles.card} style={{ width: '100%' }}>
              <div
                className={styles.subTitle}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#000',
                }}
              >
                <div
                  style={{
                    textDecoration: 'none',
                    border: 'solid #223ad2 3px',
                    borderRadius: '15px',
                    padding: '10px',
                  }}
                >
                  <div>Demais canais de atendimento:</div>
                  <br />
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#000',
                    }}
                    href="https://api.whatsapp.com/send?phone=551131925707"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>Whatsapp: (11) 3192-5707</div>
                  </a>
                  <div>Canal de voz: 0800 591 7872</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
